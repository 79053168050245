@tailwind base;
@tailwind components;
@tailwind utilities;

.uploadcare--widget__button,
.uploadcare--widget__button:hover,
.uploadcare--button,
.uploadcare--button:hover {
  padding: 14px;
  background-color: #a8d26b;
  border: 3px #2c003a;
  border-radius: 6px;
  font-weight: 800;
}

.uploadcare--button:hover,
.uploadcare--widget__button:hover,
.uploadcare--widget__button_type_open:hover {
  background-color: #84cc16;
}
